import "./App.css";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import StraightenIcon from "@mui/icons-material/Straighten";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import AddIcon from "@mui/icons-material/Add";
import AirIcon from "@mui/icons-material/Air";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";
import NotAccessibleIcon from "@mui/icons-material/NotAccessible";
import AccessibleIcon from "@mui/icons-material/Accessible";

import {
  Map,
  Source,
  Layer,
  MapProvider,
  Marker,
  useControl,
} from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Geocoder } from "@mapbox/search-js-react";

// import booleanPointOnLine from "@turf/boolean-point-on-line";
// import nearestPointOnLine from "@turf/nearest-point-on-line";
import { nearestPoint } from "@turf/nearest-point";

import * as turf from "@turf/turf";
import { featureCollection, point, geometryCollection } from "@turf/helpers";
// import { mask } from "@turf/mask";

// import BOUNDED_ROADS from "./boundedroads.geojson";
import LIBRARIES from "./libraries.geojson";
import VERTICES from "./vertices.geojson";
import EDGES from "./edges.geojson";
// import BOUNDARY_EXTENT from './bedstuyextent.geojson';
import BOUNDARY_EXTENT from "./brooklyngeojson.geojson";

const fetchJSON = async (url) => {
  const response = await fetch(url);
  return await response.json();
};

function DrawControl(props) {
  useControl(
    () => new MapboxDraw(props),
    ({ map }) => {
      map.on("draw.create", props.onCreate);
      map.on("draw.update", props.onUpdate);
      map.on("draw.delete", props.onDelete);
    },
    ({ map }) => {
      map.off("draw.create", props.onCreate);
      map.off("draw.update", props.onUpdate);
      map.off("draw.delete", props.onDelete);
    },
    {
      position: props.position,
    }
  );

  return null;
}

DrawControl.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
};

function App() {
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
  const SEND_PATH_URL = process.env.REACT_APP_ROOT_IP + "/send_path";
  const GET_TEMP_URL = process.env.REACT_APP_ROOT_IP + "/get_noaa_temp";
  const GET_AGG_EXPOSURE_URL = process.env.REACT_APP_ROOT_IP + "/get_agg_exposure";
  const GET_ADDRESS_URL = "https://api.mapbox.com/search/geocode/v6/reverse";

  // const MINIMUM_LST = 19.485378050197045;
  // const MAXIMUM_LST = 38.54812104037855;

  const [routeData, setRouteData] = useState({});
  const [algorithmOption, setAlgorithmOption] = useState("dijkstra");
  const [weightOption, setWeightOption] = useState("weightLST");
  const [displayRoute, setDisplayRoute] = useState(false);
  const [realTimeTemp, setRealTimeTemp] = useState(0);
  const [showSettings, setShowSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLST, setShowLST] = useState(false);
  const [showCoolInfrastructure, setShowCoolInfrastructure] = useState(false);
  const [showExposureTools, setShowExposureTools] = useState(false);
  const [routeScore, setRouteScore] = useState({
    lstSum: 0,
    lengthTimesLSTSum: 0,
    meanLst: 0,
    distanceMeters: 0,
  });
  const [totalExposure, setTotalExposure] = useState(0);
  const [features, setFeatures] = useState({});

  const onUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
    // console.log(
    //   `features ${JSON.stringify(
    //     features[Object.keys(features).pop()]["geometry"][
    //   "coordinates"
    // ]
    //   )} ${JSON.stringify(features)}`
    // );
  }, []);

  const onDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const getPointsInsideGeometry = (feature, points) => {
    const pointsInside = turf.pointsWithinPolygon(points, feature);
    const idsOfPointsInsideGeometry = pointsInside.features.map((point) => {
      return point.properties.id;
    });

    return idsOfPointsInsideGeometry;
  };

  const calculateTotalExposure = async (pointsInsideGeometry) => {
    pointsInsideGeometry = pointsInsideGeometry.map((points) => [points]);

    console.log(pointsInsideGeometry);
    for (let row of pointsInsideGeometry) {
      row.push(lngLatEnd.id);
    }

    const exposureValue = async () => {
      const url = new URL(GET_AGG_EXPOSURE_URL);
      await fetch(url.href, {
        method: "POST",
        body: JSON.stringify({ pointsInsideGeometry }),
      })
        .then((response) => response.json())
        .then((data) => {
          setTotalExposure(data["exposure"]);
          setLoading(false);
        });
    };

    await exposureValue();
  };

  const [mask, setMask] = useState();

  useEffect(() => {
    console.log("New route data: ", routeData);

    const getTemp = async () => {
      const url = new URL(GET_TEMP_URL);
      await fetch(url.href, { method: "GET" })
        .then((response) => response.json())
        .then((data) => {
          setRealTimeTemp(roundNum(data.realTimeTemp));
        });
    };

    getTemp();
  }, [routeData]);

  const changeAlgorithm = (e) => {
    setAlgorithmOption(e.target.value);
  };

  const changeWeightOption = (e) => {
    setWeightOption(e.target.value);
  };

  const roundNum = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

  const reverseGeocode = async (lat, long) => {
    const url = new URL(GET_ADDRESS_URL);
    url.searchParams.set("latitude", lat);
    url.searchParams.set("longitude", long);

    url.searchParams.set("access_token", MAPBOX_TOKEN);

    return await fetch(url.href, { method: "GET" });
  };

  const routeFunction = async () => {
    const url = new URL(SEND_PATH_URL);
    url.searchParams.set("beginLat", lngLatStart.lat);
    url.searchParams.set("beginLong", lngLatStart.lng);
    url.searchParams.set("endLong", lngLatEnd.lng);
    url.searchParams.set("endLat", lngLatEnd.lat);

    url.searchParams.set("beginId", lngLatStart.id);
    url.searchParams.set("endId", lngLatEnd.id);
    url.searchParams.set("algorithm", algorithmOption);
    url.searchParams.set("weight", weightOption);

    await fetch(url.href, { method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        setRouteData(JSON.parse(data.geojson));
        let meanScore = roundNum(data.mean);
        let lstSum = roundNum(data.lstSum);
        let lengthTimesLSTSum = roundNum(data.lengthTimesLSTSum);
        let distanceMeters = roundNum(data.distanceMeters);

        setRouteScore({
          meanLst: meanScore,
          lstSum: lstSum,
          lengthTimesLSTSum: lengthTimesLSTSum,
          distanceMeters: distanceMeters,
        });
        setDisplayRoute(true);
      });
  };

  const layerStyle = {
    id: "routeData",
    type: "line",
    paint: {
      "line-color": "blue",
      "line-opacity": 1,
      "line-width": 5,
      "line-dasharray": [2, 1],
    },
  };

  // const maskStyle = {
  //   id: "mask",
  //   type: "fill",
  //   paint: {
  //     "fill-color": "black",
  //     "fill-opacity": 0.1,
  //   },
  // };

  const libraryStyle = {
    id: "library",
    type: "symbol",
    layout: {
      "icon-image": "library-icon", // Built-in Mapbox icon
      "icon-size": 0.125,
    },
  };

  const edgeStyle = {
    id: "edge",
    type: "line",
    paint: {
      "line-color": [
        "interpolate",
        ["linear"],
        ["get", "weightLST"],
        19.485378050197045,
        "#00FFFF", // Blue
        29.01,
        "#FFFF00", // Yellow
        38.54812104037855,
        "#FF0000", // Red
      ],
      "line-opacity": 1,
      "line-width": 3,
    },
  };

  const edgeLabelStyle = {
    id: "edgeLabels",
    type: "symbol",
    layout: {
      "symbol-placement": "line-center",
      "text-field": ["to-string", ["round", ["get", "weightLST"]]],
      "text-size": 30,
      "text-anchor": "center",
    },
    paint: {
      "text-halo-width": 1,
      "text-halo-color": "#00A651",
      "text-font": ["Helvetica"],
      "text-color": "black",
    },
  };

  const startEnd = {
    start: "start",
    end: "end",
  };

  const [lngLatStart, setLngLatStart] = useState({
    lng: 0,
    lat: 0,
    address: "",
    id: 0,
  });

  const [lngLatEnd, setLngLatEnd] = useState({
    lng: 0,
    lat: 0,
    address: "",
    id: 0,
  });

  const setLatLong = (lngLatArr, type) => {
    fetchJSON(VERTICES).then(async (data) => {
      // Points instead
      const points = featureCollection(
        data.features.map((point) =>
          turf.point(point.geometry.coordinates, { id: point.properties.osmid })
        )
      );

      const closestPoint = nearestPoint(lngLatArr, points);

      console.log(closestPoint.geometry.coordinates);
      console.log(closestPoint.properties.id);

      let lat = closestPoint.geometry.coordinates[1];
      let lng = closestPoint.geometry.coordinates[0];
      let id = closestPoint.properties.id;

      reverseGeocode(lat, lng)
        .then((response) => response.json())
        .then((data) => {
          const address = data.features[0].properties.name;
          if (type === startEnd.start) {
            setLngLatStart({ lng, lat, address, id });
          } else if (type === startEnd.end) {
            setLngLatEnd({ lng, lat, address, id });
          }
        });

      // const coords = data.features.map((line) => line.geometry.coordinates);
      // const roadLines = turf.multiLineString(coords);

      // if (!booleanPointOnLine(markerPos, roadLines)) {

      //   const closestPoint = nearestPointOnLine(roadLines, markerPos);

      //   console.log(closestPoint.geometry.coordinates)

      //   setLatLong(closestPoint.geometry.coordinates[1], closestPoint.geometry.coordinates[0], type);

      // } else {

      //   setLatLong(event.lngLat.lat, event.lngLat.lng, type);

      // }
    });
  };

  const onEndMarkerDrag = async (event, type) => {
    const markerPos = [event.lngLat.lng, event.lngLat.lat];

    console.log(markerPos);

    setLatLong(markerPos, type);

    console.log(lngLatStart);
    console.log(lngLatEnd);
  };

  useEffect(() => {
    const bedStuyMask = async () => {
      const extents = await fetchJSON(BOUNDARY_EXTENT);

      let features = extents.geometry.geometries.map((feature) => {
        console.log(feature);
        return turf.feature(feature);
      });

      const extentsPolygon = turf.featureCollection(features);
      setMask(turf.mask(extentsPolygon));
    };

    bedStuyMask();

    setLatLong([-73.9317, 40.6905], startEnd.start);
    setLatLong([-73.92, 40.6905], startEnd.end);
  }, []);

  const mapRef = useRef(null);

  const onLoad = () => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (!map.hasImage("library-icon")) {
        map.loadImage("coolsite.png", (error, image) => {
          if (error) throw error;
          map.addImage("library-icon", image);
        });
      }
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      if (
        map.getLayer("routeData") &&
        map.getLayer("edge") &&
        map.getLayer("edgeLabels")
      ) {
        // map.moveLayer('edge', 'edgeLabels', 'routeData');
        map.moveLayer("edge");

        console.log(map.getStyle().layers);
      }
    }
  }, [showLST]);

  const [popupInfo, setPopupInfo] = useState(null);

  const handleHover = (event) => {
    const feature = event.features && event.features[0];

    if (feature) {
      const [longitude, latitude] = feature.geometry.coordinates;

      setPopupInfo({
        longitude,
        latitude,
        name: feature.properties.libraryName,
        description: feature.properties.access,
      });
    }
  };

  const handleClosePopup = () => {
    setPopupInfo(null); // Hide the popup when the mouse leaves
  };

  return (
    <Stack alignItems="center" sx={{ height: "auto" }}>
      <Stack
        className="header"
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing="20vw"
        sx={{
          position: "fixed",
          backgroundColor: "white",
          zIndex: "5",
          width: "100vw",
          padding: "20px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="left"
          spacing="25px"
          sx={{ width: "25vw" }}
        >
          <img
            alt="Cool Corridor Routing"
            src="coco-logo.png"
            width="100px"
          ></img>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="right"
          spacing="25px"
          sx={{ width: "25vw" }}
        >
          <img
            alt="Columbia"
            src="https://www.earth.columbia.edu/img/logos/logo-ei-blue-400.png"
            width="200px"
          ></img>
          {/* <img alt="NASA Meatball" src="https://gpm.nasa.gov/sites/default/files/NASA-Logo-Large.jpg" width="50px"></img> */}
          <img
            alt="City Tech"
            src="https://historyprogram.commons.gc.cuny.edu/files/2015/08/CityTech.jpeg"
            width="40px"
          ></img>
        </Stack>
      </Stack>

      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "auto", marginTop: "80px" }}
      >
        <Box sx={{ width: "70vw", margin: "30px 0px 20px 0px" }}>
          {/* <h1>Cool Corridor Routing</h1> */}
          {/* <img alt="Cool Corridor Routing" src="coco-black.png" width="300px"></img> */}
          <Box sx={{ textAlign: "left" }}>
            <h1>Helping you find the coolest, most comfortable route</h1>
          </Box>
          <p>
            Select a start and end point by dragging the markers designated 'S'
            and 'E,' respectively. The coolest path will be generated. An LST
            score for this path will appear in the top left corner under 'LST
            Score.'
          </p>
        </Box>
        <Stack alignItems="center">
          <Box sx={{ width: "80vw", height: "50rem" }}>
            <MapProvider>
              <Map
                id="coolMap"
                mapboxAccessToken={MAPBOX_TOKEN}
                initialViewState={{
                  longitude: -73.9317,
                  latitude: 40.6905,
                  zoom: 13,
                  pitch: 15,
                }}
                onLoad={onLoad}
                ref={mapRef}
                interactiveLayerIds={["library"]}
                onMouseMove={handleHover}
                // onMouseLeave={handleClosePopup}
                mapStyle="mapbox://styles/mapbox/standard"
                className="mapContainer"
                // terrain={{"source": "mapbox-raster-dem", "exaggeration": 2}}
              >
                {/* <FullscreenControl position='bottom-left'/>  */}

                {/*<div className="sidebar">
                  <div>
                    <h2>Path Metrics</h2>
                  </div>

                  <Box>
                    <hr/>
                    <p>Mean exposure: {routeScore.meanLst} °C</p>
                    <p>Cumulative heat exposure: {routeScore.heatUnits} degree / meter</p>

                  </Box>

                </div>*/}
                {/*<hr/>
                    <p>Heat exposure per minute: </p>
                    <p>Estimated travel time: </p>*/}

                <Stack direction="column" spacing="3" className="sendDiv">
                  <Stack
                    direction="column"
                    spacing="20px"
                    className="fitContent"
                  >
                    <Box className="innerSendDiv">
                      <h2>Path Exposure Metrics</h2>
                      <hr />
                      <p sx={{ display: "flex", justifyContent: "center" }}>
                        <ThermostatIcon
                          sx={{
                            marginRight: 1,
                            fontSize: "1.25rem",
                            verticalAlign: "bottom",
                          }}
                        />
                        Mean exposure:{" "}
                        {routeScore.meanLst !== 0
                          ? ((routeScore.meanLst * 9) / 5 + 32).toFixed(3)
                          : 0}{" "}
                        °F
                      </p>

                      <p sx={{ display: "flex", justifyContent: "center" }}>
                        <AddIcon
                          sx={{
                            marginRight: 1,
                            fontSize: "1.25rem",
                            verticalAlign: "bottom",
                          }}
                        />
                        Cumulative heat exposure: {routeScore.lengthTimesLSTSum}{" "}
                        degree * meter
                      </p>

                      <p sx={{ display: "flex", justifyContent: "center" }}>
                        <StraightenIcon
                          sx={{
                            marginRight: 1,
                            fontSize: "1.25rem",
                            verticalAlign: "bottom",
                          }}
                        />
                        Path distance:{" "}
                        {((routeScore.distanceMeters * 3.28084) / 5280).toFixed(
                          3
                        )}{" "}
                        miles
                      </p>

                      <p sx={{ display: "flex", justifyContent: "center" }}>
                        <AirIcon
                          sx={{
                            marginRight: 1,
                            fontSize: "1.25rem",
                            verticalAlign: "bottom",
                          }}
                        />
                        Weather station (KJFK) air temperature:{" "}
                        {realTimeTemp !== 0
                          ? ((realTimeTemp * 9) / 5 + 32).toFixed(3)
                          : 32}{" "}
                        °F
                      </p>
                      {/*<hr/>
                      <p>Heat exposure per minute: </p>
                      <p>Estimated travel time: </p>*/}
                    </Box>

                    {showExposureTools && (
                      <Box className="innerSendDiv">
                        <h3>Lower Bound Exposure</h3>
                        <hr />
                        <p>
                          Exposure in region: {totalExposure} degree * meter
                        </p>
                      </Box>
                    )}
                    {showCoolInfrastructure && (
                      <Stack
                        direction="column"
                        spacing="20px"
                        className="innerSendDiv fitContent"
                        sx={{ width: "100% !important" }}
                      >
                        <Box>
                          <h3>Cool Infrastructure</h3>
                          <hr />
                          {popupInfo && (
                            <Stack direction="row" alignItems="center">
                              {popupInfo.description === "fully accessible" && (
                                <AccessibleForwardIcon />
                              )}
                              {popupInfo.description === "not accessible" && (
                                <NotAccessibleIcon />
                              )}
                              {popupInfo.description ===
                                "partially accessible" && <AccessibleIcon />}

                              <p>
                                {popupInfo.name}, {popupInfo.description}
                              </p>
                            </Stack>
                          )}
                        </Box>
                      </Stack>
                    )}
                  </Stack>
                </Stack>

                <Stack
                  direction="column"
                  spacing="20px"
                  className="leftSendDiv"
                >
                  {showExposureTools ? (
                    <LoadingButton
                      variant="contained"
                      sx={{ backgroundColor: "#00A651", width: "100%" }}
                      loading={loading}
                      onClick={async () => {
                        if (features[Object.keys(features)[0]]) {
                          setLoading(true);

                          const feature = turf.polygon(
                            features[Object.keys(features).pop()]["geometry"][
                              "coordinates"
                            ]
                          );
                          const data = await fetchJSON(VERTICES);

                          // Points instead
                          const points = featureCollection(
                            data.features.map((point) =>
                              turf.point(point.geometry.coordinates, {
                                id: point.properties.osmid,
                              })
                            )
                          );

                          const pointsInsideGeometry = getPointsInsideGeometry(
                            feature,
                            points
                          );

                          calculateTotalExposure(pointsInsideGeometry);
                        } else {
                          alert("ERROR: Select a region.");
                        }
                      }}
                    >
                      Calculate lower bound exposure
                    </LoadingButton>
                  ) : (
                    <Button
                      className="sendButton"
                      variant="contained"
                      onClick={async () => {
                        await routeFunction();
                      }}
                    >
                      {" "}
                      Find Path{" "}
                    </Button>
                  )}

                  <Stack
                    direction="column"
                    spacing="20px"
                    className="innerSendDiv"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {/* Edit to be controlled input */}
                    <Geocoder
                      accessToken={MAPBOX_TOKEN}
                      placeholder="Start"
                      value={lngLatStart.address}
                      onRetrieve={(e) => {
                        setLatLong(e.geometry.coordinates, startEnd.start);
                        setRouteScore({
                          meanLst: 0,
                          lstSum: 0,
                          lengthTimesLSTSum: 0,
                          distanceMeters: 0,
                        });
                        setDisplayRoute(false);
                      }}
                      options={{
                        country: "US",
                      }}
                    />

                    <Geocoder
                      accessToken={MAPBOX_TOKEN}
                      placeholder="Destination"
                      value={lngLatEnd.address}
                      onRetrieve={(e) => {
                        setLatLong(e.geometry.coordinates, startEnd.end);
                        setRouteScore({
                          meanLst: 0,
                          lstSum: 0,
                          lengthTimesLSTSum: 0,
                          distanceMeters: 0,
                        });
                        setDisplayRoute(false);
                      }}
                      options={{
                        country: "US",
                      }}
                    />
                  </Stack>

                  <Stack
                    direction="column"
                    spacing="20px"
                    className="innerSendDiv"
                  >
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel>Algorithm</InputLabel>
                        <Select
                          value={algorithmOption}
                          label="Algorithm"
                          onChange={changeAlgorithm}
                        >
                          <MenuItem value={"dijkstra"}>Dijkstra</MenuItem>
                          <MenuItem value={"normalized"}>
                            Normalized Dijkstra
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl fullWidth>
                        <InputLabel>Weight</InputLabel>
                        <Select
                          value={weightOption}
                          label="Algorithm"
                          onChange={changeWeightOption}
                        >
                          <MenuItem value={"weightLST"}>LST</MenuItem>
                          <MenuItem value={"weightLengthTimesLST"}>
                            LST * length
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Stack>

                  <Stack
                    direction="column"
                    spacing="20px"
                    className="innerSendDiv"
                  >
                    {showSettings && (
                      <>
                        <FormGroup>
                          {/* <FormControlLabel
                            control={<Switch />}
                            label="Modify paths by tree density?"
                          /> */}
                          <FormControlLabel
                            control={<Switch checked={showLST} />}
                            label="View edge weights"
                            onChange={() => {
                              setShowLST((prevState) => !prevState);
                            }}
                          />
                          <FormControlLabel
                            control={
                              <Switch checked={showCoolInfrastructure} />
                            }
                            label="Display cool infrastructure"
                            onChange={() => {
                              setShowCoolInfrastructure((prevState) => {
                                setPopupInfo(null);
                                return !prevState;
                              });
                            }}
                          />
                          <FormControlLabel
                            control={<Switch />}
                            label="Calculate exposure"
                            checked={showExposureTools}
                            onChange={() => {
                              setShowExposureTools((prevState) => !prevState);
                              setDisplayRoute(false);
                            }}
                          />
                        </FormGroup>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setShowSettings((prevState) => !prevState);
                          }}
                        >
                          Close
                        </Button>
                      </>
                    )}
                    {!showSettings && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setShowSettings((prevState) => !prevState);
                        }}
                      >
                        Show Settings
                      </Button>
                    )}
                  </Stack>

                  {/* <Stack direction="column" spacing="20px" className="innerSendDiv">
                    <h2>Ok with a longer, cooler route?</h2>
                    {showSettings && <>
                      <p>How many more meters are you willing to walk? Specify a maximum path length:</p>
                      <TextField onChange={(e) => {setMaxLength(e.target.value)}} id="outlined-basic" label={`${maxLength} Meters`} variant="outlined" />
                    </>}
                    
                    {
                      showSettings &&
                      <Button variant="outlined" onClick={() => {setShowSettings(prevState => !prevState)}}>Close</Button>
                    }

                    {
                      !showSettings &&
                      <Button variant="outlined" onClick={() => {setShowSettings(prevState => !prevState)}}>See Options</Button>
                    }

                  </Stack> */}
                </Stack>

                {/* 
                <Source id="boundary" type="geojson" data={mask}>
                  <Layer {...maskStyle} />
                </Source> */}

                {showCoolInfrastructure && (
                  <Source id="library" type="geojson" data={LIBRARIES}>
                    <Layer {...libraryStyle} />
                  </Source>
                )}

                {displayRoute && (
                  <Source id="routeData" type="geojson" data={routeData}>
                    <Layer {...layerStyle} />
                  </Source>
                )}

                {showLST && (
                  <>
                    <Source id="edge" type="geojson" data={EDGES}>
                      <Layer
                        {...edgeStyle}
                        beforeId={displayRoute ? "routeData" : ""}
                      />
                    </Source>
                    <Source id="edgeLabels" type="geojson" data={EDGES}>
                      <Layer {...edgeLabelStyle} />
                    </Source>
                  </>
                )}

                {showExposureTools && (
                  <DrawControl
                    position="bottom-left"
                    displayControlsDefault={false}
                    controls={{
                      polygon: true,
                      trash: true,
                    }}
                    defaultMode="simple_select"
                    onCreate={onUpdate}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                  />
                )}

                <Marker
                  draggable
                  longitude={lngLatEnd.lng}
                  latitude={lngLatEnd.lat}
                  onDragStart={(e) => {
                    setDisplayRoute(false);
                    setRouteScore({
                      meanLst: 0,
                      lstSum: 0,
                      lengthTimesLSTSum: 0,
                      distanceMeters: 0,
                    });
                  }}
                  onDragEnd={(e) => onEndMarkerDrag(e, startEnd.end)}
                  anchor="bottom"
                >
                  <img
                    src="end.png"
                    width="30px"
                    height="auto"
                    alt="End point"
                  />
                </Marker>

                {!showExposureTools && (
                  <Marker
                    draggable
                    longitude={lngLatStart.lng}
                    latitude={lngLatStart.lat}
                    onDragStart={(e) => {
                      setDisplayRoute(false);
                      setRouteScore({
                        meanLst: 0,
                        lstSum: 0,
                        lengthTimesLSTSum: 0,
                        distanceMeters: 0,
                      });
                    }}
                    onDragEnd={(e) => onEndMarkerDrag(e, startEnd.start)}
                    anchor="bottom"
                  >
                    <img
                      src="start.png"
                      width="30px"
                      height="auto"
                      alt="Start point"
                    />
                  </Marker>
                )}
              </Map>
            </MapProvider>
          </Box>
        </Stack>
      </Stack>
      <Stack sx={{ width: "70vw", margin: "30px" }}>
        <Box sx={{ textAlign: "justify" }}>
          <h1>What does this tool do?</h1>
          <p>
            {" "}
            Cool corridor routing software aims to help pedestrians find the
            coolest way to a cooling zone or to their home so that they can
            avoid heat related discomfort. Officials may also generate heat maps
            using tools like this to identify streets that become dangerously
            hot during extreme heat events.
          </p>
        </Box>

        <Box sx={{ textAlign: "justify" }}>
          <h1>How are cool paths calculated?</h1>
          <p>
            Cool paths are calculated using a modified version of Dijkstra's
            algorithm. You can read more about Dijkstra's algorithm{" "}
            <a href="https://en.wikipedia.org/wiki/Dijkstra%27s_algorithm">
              here
            </a>
            . An LST image is projected over a road network and each LST pixel
            intersecting each road in this road network is added to a mean LST
            for each road. Once the graph has been generated using the
            intersecting LST pixels, Dijkstra's algorithm is run over the edges
            in the graph.
          </p>

          <h1>Weight options</h1>
          <h2>LST</h2>
          <p>
            The LST weight option uses the mean LST of each road as the weight
            for each edge in the graph. This option will generate the coolest
            path that traverses the fewest number of edges.
          </p>
          <h2>LST * length</h2>
          <p>
            The LST * length weight option uses the product of the mean LST of
            each road and the length of the road as the weight for each edge in
            the graph. This option will generate the coolest path that is the
            shortest by distance (in meters).
          </p>
        </Box>

        <Box sx={{ textAlign: "justify" }}>
          <h1>Team and Affiliations</h1>
          <p>
            Matthew Bilik (University of Michigan), Jack Lefkowitz (Cornell
            University), Hamidreza Norouzi (New York City College of
            Technology), Reginald Blake (New York City College of Technology)
          </p>
          {/* <p>NASA GISS Climate Impacts Group (CIG) with support from the NASA Advanced Information Systems Technology program</p> */}
        </Box>
      </Stack>

      <footer className="footer">
        <div>
          {/* <p>NASA Goddard Institute for Space Studies</p>
          <p>NASA Advanced Information Systems Technology</p> */}
          <p>New York City College of Technology</p>
          <p>Columbia University</p>
        </div>
      </footer>
    </Stack>
  );
}

export default App;
